<template>
  <div>
    <ed-consulta
      :intConsultaId="$route.params.intId"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import EdConsulta from "@/components/consulta/editar";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdConsulta },
  mounted() {
    if (!this.$route.params.intId) {
      this.$router.push({ name: "consulta" });
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>